<template>
	<ScrollingDocument
		v-slot:default="{ page, isPageFocused }"
		class="pdf-preview"
		:pages="pages"
		:page-count="pageCount"
		:current-page="currentPage"
		:is-parent-visible="isPreviewEnabled"
		@pages-fetch="onPagesFetch"
		@page-jump="onPageJump"
	>
		<PDFThumbnail
			:scale="scale"
			:page="page"
			:is-page-focused="isPageFocused"
			@thumbnail-rendered="onThumbnailRendered"
			@thumbnail-errored="onThumbnailErrored"
			@page-focus="onPageFocused"
		/>
	</ScrollingDocument>
</template>

<script>
export default {
	name: 'PDFPreview',
	components: {
		ScrollingDocument: () => ({
			component: import('@/components/Documents/Preview/Viewers/PDFViewer/ScrollingDocument')
		}),
		PDFThumbnail: () => ({
			component: import('@/components/Documents/Preview/Viewers/PDFViewer/PDFThumbnail')
		})
	},
	props: {
		pages: {
			required: true,
			type: Array
		},
		pageCount: {
			required: false,
			type: Number,
			default: 0
		},
		scale: {
			required: false,
			type: Number,
			default: 1.0
		},
		currentPage: {
			required: false,
			type: Number,
			default: 1
		},
		isPreviewEnabled: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	methods: {
		onPagesFetch (currentPage) {
			this.$emit('pages-fetch', currentPage)
		},
		onPageJump (scrollTop) {
			this.$el.scrollTop = scrollTop
		},
		onPageFocused (pageNumber) {
			this.$emit('page-focus', pageNumber)
		},
		onThumbnailRendered (payload) {
			this.$el.dispatchEvent(new Event('scroll'))
			this.$emit('thumbnail-rendered', payload)
		},
		onThumbnailErrored (payload) {
			this.$emit('thumbnail-errored', payload)
		}
	}
}
</script>

<style scoped>
.pdf-preview {
	position: absolute;
	overflow: auto;
	z-index: 1;
	padding: 2em 0;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.scrolling-page {
	margin-top: 1em;
	margin-bottom: 1em;
}

@media print {
	.pdf-preview {
		display: none;
	}
}
</style>
